$base-color: #03a9f4;

$black-color: #212121;

/*$base-color-dark: #4E4584;*/
$base-color-dark: #007ac1;
$base-color-dark-2: #005b9f;
$border-color: #D9D9D9;
$box-shadow-red-color: rgba(220, 53, 69, 0.5);
$box-shadow-grey-color: rgba(0, 0, 0, 0.16);
$background-color-default: #F6F9FC;
$base-color-light: #EAF1F9;
$input-disabled-font-color: #8898AA;
$input-background-color: #F7FAFC;
$input-disabled-background-color: #E9ECEF;
$background-color-disabled: #F5F5FC;
$html-background-color: #f4f6f9;
$transparent-grey-color: rgba(0, 0, 0, 0.16);
$controls-default-height: 28px;
$accent-color: #67daff;
$default-border-color: #707070;
$default-black: #212529;
$controls-pink: #fedada;
$controls-pink-hover: #febaba;

div.input-group {
    background: #F7FAFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    color: #F7FAFC;

    & > input {
        border: 0px;
        background-color: #F7FAFC;
    }

    & > .input-group-append > * {
        border: 0px;
    }
}

label.custom-control-label {
    font-weight: normal !important;
    letter-spacing: 0px;
    color: #535353;
}


.tooltip-inner {
    background-color: $base-color;
    color: #fff;
    max-width:none;
}

.tooltip.top .tooltip-arrow:before,
.tooltip.top .tooltip-arrow {
    border-top-color: $base-color;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
}

.custom-checkbox {
    .custom-control-label {
        cursor: pointer;
        font-size: 0.9rem;

        &::before {
            border: 1px solid $border-color;
            border-radius: 3px;
            background-color: #fff;
        }
    }

    .custom-control-input {
        &:checked ~ .custom-control-label {
            &::before {
                border: 1px solid $border-color;
                border-radius: 3px;
                background-color: #fff;
            }

            &::after {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FF6F00' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
            }
        }

        &:disabled, &.disabled {
            .custom-control-label {
                cursor: not-allowed;
            }

            &:checked ~ .custom-control-label {

                &::before {
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    background-color: #ccc;
                }
            }
        }
    }
}

.login-company-radio {
    word-break: break-word;
}

.custom-radio {
    .custom-control-label {
        cursor: pointer;

        &::before {
            background-color: #fff;
        }

        &:focus {
            &:before {
                background-color: $base-color-light;
            }
        }
    }

    .custom-control-input {
        &:checked ~ .custom-control-label {
            &::before {
                border-color: $base-color;
                background-color: $base-color;
            }
        }

        &:disabled, &.disabled, &[disabled]:not([disabled="false"]) {
            cursor: not-allowed;

            &:checked ~ .custom-control-label, & ~ .custom-control-label {
                cursor: not-allowed;

                &::before {
                    border-color: #ccc;
                    background-color: #ccc;
                }
            }
        }
    }
}


.btn-im {
    color: #fff;
    background-color: $base-color;
    border-color: $base-color;

    &:hover {
        color: #fff;
        background-color: $base-color-dark;
        border-color: $base-color-dark-2;
    }

    &:focus, &.focus {
        color: #fff;
        background-color: $base-color-dark;
        border-color: $base-color-dark-2;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    &.disabled, &:disabled {
        color: #fff;
        background-color: $base-color;
        border-color: $base-color;
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: $base-color-dark;
        border-color: $base-color-dark-2;
    }

    &.big {
        margin-top: 20px;
        width: 100%;
        height: 150px;
    }
}

.btn-im:not(:disabled):not(.disabled):active:focus, .btn-im:not(:disabled):not(.disabled).active:focus,
.show > .btn-im.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

a {
    color: $base-color;

    &:hover {
        color: $base-color-dark;
    }

    &:focus {
        color: $base-color-dark;
    }
}

.btn-outline-im {
    color: $base-color;
    border-color: $base-color;

    &:hover {
        color: #fff;
        background-color: $base-color-dark;
        border-color: $base-color-dark-2;
    }

    &.disabled, &:disabled {
        color: $base-color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: #fff;
        background-color: $base-color;
        border-color: $base-color;
    }
}

.nav-pills {
    .nav-item {
        margin-top: 5px;
        margin-bottom: 5px;

        &.has-treeview {
            margin-bottom: 0px;

            .nav-treeview .nav-item {
                margin-top: 0px;
                margin-bottom: 0px;

                .nav-link {
                    padding-left: 25px;
                    background: $base-color-light 0% 0% no-repeat padding-box;
                    border-radius: 4px;

                    &:not(.active):hover {
                        color: $base-color-dark;
                    }

                    &.active {
                        color: #fff;
                        background: $base-color 0% 0% no-repeat padding-box;
                        border-radius: 4px;
                    }
                }
            }

            & > .nav-link.active {
                color: $base-color;
                background: $background-color-default 0% 0% no-repeat padding-box;
                border-radius: 3px;
            }
        }

        .show > .nav-link {
            color: #fff;
            background: $base-color 0% 0% no-repeat padding-box;
            border-radius: 4px;
        }

        .nav-link {
            color: $base-color;
            background: $background-color-default 0% 0% no-repeat padding-box;
            border-radius: 3px;

            &:not(.active):hover {
                color: $base-color-dark;
            }

            &.active {
                color: #fff;
                background: $base-color 0% 0% no-repeat padding-box;
                border-radius: 4px;
            }
        }
    }
}

.navbar-nav .nav-item .nav-link {
    color: $base-color;

    &:hover {
        color: $base-color-dark;
    }

    &:focus {
        color: $base-color-dark;
    }
}

.main-sidebar {
    /*    height: 100vh !important;*/
    background-color: #fff;

    .brand-link {
        background-color: white;
        color: $black-color;

        .brand-image {
            margin-left: .1rem;
        }

        &:hover {
            .brand-text {
                color: $black-color;
            }
        }
    }

    .user-panel {
        padding-left: 0;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        padding-bottom: 0 !important;

        .info {
            padding-left: 0;
        }
    }
}

.sidebar {
    height: 100%;
    overflow: hidden;
}

html {
    background-color: $html-background-color;
}

.nav-user-menu .nav-link .user-image {
    background-color: $accent-color;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    color: #fff;
}

.page-item {
    &.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: $base-color;
        border-color: $base-color;
    }

    &.disabled .page-link {
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }
}

.page-link {
    color: $base-color;
}

.freeze-ui:before {
    border-color: transparent $base-color $base-color;
    top: calc(50% - 37.5px);
    left: calc(50% - 37.5px);
}

.user-panel .info span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $base-color;
}

div.ps__thumb-y, div.ps__thumb-x {
    background-color: $base-color;
}

.ps__rail-y, .ps__rail-x {
    &.ps--clicking, &:focus, &:hover {
        div.ps__thumb-y, div.ps__thumb-x {
            background-color: $base-color;
        }
    }
}

.ps-content-wrapper {
    height: calc(100vh - 57px);
}

.dropdown-item {
    &:focus {
        background-color: $base-color;
    }
}

.swal2-confirm.swal2-styled {
    background-color: $base-color;
}

.content {
    padding-top: 15px;

    .card-body {
        padding: 0.5rem;
    }
}

.content-header {
    padding: .5rem .5rem;

    h1 {
        color: $base-color;
        font-weight: bold;
        padding-left: 15px;
        border-left: 4px solid;
        font-size: 1.5rem;
    }
}

.btn-transparent-im {
    color: $base-color;

    &:hover {
        color: $base-color-dark;
    }

    &:focus, &.focus {
        color: $base-color-dark;
    }

    &.disabled, &:disabled {

        &, &:hover {
            color: $input-disabled-font-color;
            cursor: not-allowed;
        }
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
        color: $base-color-dark;
    }

    &.toggled {
        border-color: $base-color;
        border-width: 1px;


        &.disabled, &:disabled {
            border-color: $input-disabled-font-color;
        }
    }
}

.select-group {
    position: relative;
    padding-bottom: 0.6rem;
    font-size: 0.9rem;

    .ng-select {

        &.ng-select-multiple {
            .ng-select-container {
                min-height: $controls-default-height;

                & > .ng-value-container {
                    padding-top: 2px;

                    & > .ng-value {
                        margin-bottom: 2px;
                    }
                }
            }
        }

        &.ng-select-single {

            .ng-select-container {
                height: $controls-default-height;
                min-height: $controls-default-height;
            }
        }

        &.ng-select-disabled {
            .ng-select-container, .ng-select-container:hover, .ng-select-container:focus, .ng-select-container:active {
                background: $input-disabled-background-color !important;
                color: $input-disabled-font-color !important;
            }

            .ng-arrow-wrapper {
                color: $base-color;

                .ng-arrow {
                    border-color: $input-disabled-font-color transparent transparent;
                }
            }
        }

        &, &.ng-select-opened {
            .ng-select-container, .ng-select-container:hover, .ng-select-container:focus, .ng-select-container:active {
                background-color: $input-background-color !important;
                color: $base-color !important;
                box-shadow: 0px 3px 6px $box-shadow-grey-color;
                border: none;
            }

            .ng-dropdown-panel {
                box-shadow: 0px 3px 6px $box-shadow-grey-color;
                border: none;

                .ng-dropdown-panel-items {

                    .ng-option-selected, .ng-option-selected.ng-option.ng-option-marked {
                        background-color: $base-color;
                        color: white;
                    }

                    .ng-option.ng-option-marked {
                        background-color: $base-color-light;
                    }
                }
            }

            .ng-clear-wrapper {
                color: $base-color;
            }

            .ng-arrow-wrapper {
                color: $base-color;

                .ng-arrow {
                    border-color: $base-color transparent transparent
                }
            }
        }

        &.ng-select-focused:not(.ng-select-opened) {
            & > .ng-select-container, & > .ng-select-container:hover, & > .ng-select-container:focus, & > .ng-select-container:active {
                box-shadow: 0px 3px 6px $box-shadow-grey-color;
                border: none;
            }
        }
    }

    &:last-child {
        padding-bottom: 0;

        .freeze-ui {
            height: calc(100%);
        }
    }

    .freeze-ui {
        height: calc(100% - 1rem);

        &::before {
            width: 30px;
            height: 30px;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
        }
    }
}

.small-freeze-ui {
    position: relative;

    .freeze-ui {
        &::before {
            width: 30px;
            height: 30px;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
        }
    }
}

.checkbox-group {
    padding-bottom: 0.6rem;
    height: calc(#{$controls-default-height} + 0.6rem);
    display: table;

    .custom-checkbox {
        vertical-align: middle;
        display: table-cell;
    }
}

.disable-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.table {
    th {
        padding: 5px;
        font-weight: 400;
        font-size: 14px;
        color: $input-disabled-font-color;
    }
}

.table-report {
    width: 100%;
/*    border: 1px solid #dee2e6;
    border-collapse: collapse;*/

    & > tbody {
        & > thead {
            & > th {
                text-align: center;
                border: 1px solid #dee2e6;
                border-collapse: collapse;
            }
        }

        & > tr {
            & > td {
                text-align: center;
                border: 1px solid #dee2e6;
                border-collapse: collapse;
            }
        }
    }
}

.table-hover {
    & > tbody {
        & > tr {
            &.image-collection-selected {
                background: $base-color;
                color: white;

                &:hover {
                    background: $base-color;
                    color: white;
                }
            }

            &:hover {
                background: $base-color-light;
            }
        }
    }
}


.textbox {
    background-color: $input-background-color;
    color: $base-color;
    box-shadow: 0px 3px 6px $box-shadow-grey-color;
    border: none;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;

    &:disabled, &.disabled {
        background: $input-disabled-background-color;
        color: $input-disabled-font-color;
        cursor: not-allowed;

        &::placeholder {
            color: $input-disabled-font-color;
        }
    }

    &:focus, :active {
        border: none;
        outline: none !important;
    }

    &.wide {
        width: 100%;
        min-height: $controls-default-height;
    }
}

body {

    & > .freeze-ui {
        z-index: 999999;
    }
}

.freeze-ui {
    z-index: 100;
}

.table-fixed {
    thead {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;

        th {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: 1;
            background-color: #f8f9fa;
        }
    }
}

.mat-mdc-form-field {
    &.custom-date-picker {
        color: #495057;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 5px;
        width: 100%;
        font-family: inherit;

        &:disabled, &.disabled, &.mat-form-field-disabled {
            background: $input-disabled-background-color;
            color: $input-disabled-font-color;
            cursor: not-allowed;

            .mat-mdc-form-field-infix {
                input {
                    cursor: not-allowed
                }
            }

            .mat-mdc-form-field-icon-suffix {

                button {
                    color: $base-color-dark-2;
                    cursor: not-allowed
                }
            }

            &::placeholder {
                color: $input-disabled-font-color;
            }
        }

        &.wide {
            width: 100%;
            min-height: 36px;
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }

        .mat-mdc-form-field-flex {
            height: 30px;
        }

        &.mat-form-field-appearance-fill {

            .mat-mdc-text-field-wrapper {
                padding: 0;
                height: 100%;
                display: flex;
                align-items: center;
                background-color: unset !important;

                .mat-mdc-form-field-focus-overlay {
                    background-color: transparent !important;
                }

                .mat-mdc-form-field-flex {
                    padding: 0;
                    background-color: unset;
                    display: flex;
                    align-items: center;

                    .mat-mdc-form-field-infix {

                        .mat-form-field-label-wrapper {
                            top: 0;
                            padding-top: 0;
                        }
                    }

                    .mat-mdc-form-field-icon-suffix {

                        .mat-datepicker-toggle {
                            .mat-mdc-icon-button {
                                --mat-mdc-button-ripple-color: transparent;
                                --mat-mdc-button-persistent-ripple-color: none;
                                padding: 0;
                            }

                            button {
                                height: 100%;
                                width: 100%;
                                color: $base-color;
                            }
                        }
                    }
                }

                .mdc-line-ripple {
                    display: none;
                }
            }
        }
    }
}

.mat-calendar {
    &.custom-calendar {
        background-color: $input-background-color;
        box-shadow: 0px 3px 6px $box-shadow-grey-color;
        border: none;
        border-radius: 4px;

        .mat-calendar-header {
            padding-top: 0;

            .mat-calendar-controls {
                margin-top: 0;
                margin-bottom: 0;

                button {
                    &.mat-button-base {
                        outline: none;
                    }
                }
            }
        }

        .mat-calendar-table-header {
            display: none;
        }

        .mat-calendar-body {

            tr:first-child {
                td:not([role=gridcell]) {
                    visibility: hidden;

                    &[colspan="7"] {
                        display: none;
                    }
                }
            }

            .mat-calendar-body-today:not(.mat-calendar-body-selected) {
                border: 0;
            }

            .mat-calendar-body-cell-content {
                color: $base-color;

                &.mat-calendar-body-selected {
                    color: white;
                    background-color: $base-color;
                }
            }

            .day-selected {
                &, &:hover {
                    .mat-calendar-body-cell-content {
                        background-color: $base-color;
                        color: white;
                    }
                }
            }
        }

        .mat-button, .mat-icon-button, .mat-stroked-button {
            color: $base-color;
        }

        .mat-calendar-arrow {
            border-top-color: $base-color;
        }
    }
}

div.cdk-overlay-container {
    z-index: 1100;
}

.vertical-center-content {
    display: flex;
    align-items: center;
}

.btn-dropdown-im {
    color: $base-color;
    display: flex;
    justify-content: flex-start;
    border-radius: unset;
    width: 100%;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: $base-color-dark;

        svg {

            path {
                fill: white;

                &.cls-4 {
                    fill: $base-color-dark;
                }

                &.cls-1, &.cls-3 {
                    stroke: white;
                }
            }

            rect {
                fill: white;
            }

            polygon {
                fill: white;
            }

            line {
                stroke: white;
            }

            ellipse {
                stroke: white;
            }
        }
    }

    &.disabled, &:disabled {
        color: $base-color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: #fff;
        background-color: $base-color;
    }

    & > i {
        margin-right: 7px;
        align-self: center;
    }
}

.dropdown-action-buttons {
    margin: 0px;
    padding: 0px;
    border: 0px;

    .mat-icon {
        width: 0.9em;
    }

    .mat-icon > svg {
        padding-bottom: 2px;
    }
}

.dropdown-menu {
    .mat-icon {
        width: 2em;
    }

    .mat-icon > svg {
        padding-right: 5px;
    }
}

.float-right {
    float: right;
}

$entry-item-background: #F4F6F8;
$entry-item-background-hover: #9cd0ff;
$marked-for-remove-color: #cdcdcd;

.entry-card {
    padding: 0px 5px 0px 0px;
}

.entry-item {
    border: solid #e8e8e8 1px;
    border-radius: 15px;
    margin-bottom: 20px;
    background-color: $entry-item-background;
    overflow: hidden;

    .item-header {
        border-bottom: solid $default-border-color 1px;
        font-weight: bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-left: 10px;
    }

    .item-id {
        font-weight: bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-left: 10px;
        color: #2C7DC7;
    }

    .item-name {
        /*border-bottom: solid $default-border-color 1px;*/
        font-weight: bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-left: 10px;
    }


    .item-body {
        padding-left: 10px;
        font-size: 0.9rem;
        height: 40px;
    }

    .item-buttons {
        display: flex;
        justify-content: right;
        position: absolute;
        bottom: 30px;
        right: 18px;

        .item-button {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }

            .btn {
                padding: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .entry-document-image {
        height: 90px;
        max-height: 90px;
        width: 90px;
        min-width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > .btn {
            height: 100%;
            width: 100%;

            & > i {
                font-size: 60px;
                color: $base-color;
            }
        }
    }

    .entry-image {
        height: 243px;
        max-height: none;
        width: 150px;
        min-width: none;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        position: relative;

        .img-preview {
            min-width: 150px;
            min-height: 100%;
            max-width: none;
            max-height: none;
            object-fit: cover;
        }

        & > .btn {
            height: 100%;
            width: 100%;

            & > i {
                font-size: 60px;
                color: $base-color;
            }
        }
    }

    .no-img {
        object-fit: contain !important;
    }

    .status-overlay {
        color: white;
        position: absolute;
        /*        bottom: 20.5px;
        left: 8px;*/
        bottom: 0px;
        left: 0px;
        padding: 5px;
        border-radius: 0px 0px 0px 3px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        height: 25px;
    }

    .status-color-red {
        background-color: rgba(232, 93, 93, 0.8);
    }

    .status-color-yellow {
        background-color: rgba(245, 177, 0, 0.8);
    }

    .status-color-green {
        background-color: rgb(40, 167, 69, 0.8);
    }

    .info-image {
        height: 40px;
        max-height: 40px;
        width: 25px;
        min-width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: inline-block;
        position: absolute;
        right: 30px;
        bottom: 30px;

        .btn {
            width: 38px;
        }
    }

    .entry-details {
        width: 100%;
    }

    .entry-image + .entry-details {
        width: calc(100% - 150px);
    }

    .item-body {
        height: 65px;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-4lines {
        height: 85px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-5lines {
        height: 110px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-6lines {
        height: 135px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-7lines {
        height: 160px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-8lines {
        height: 185px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-9lines {
        height: 210px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    .item-body-10lines {
        height: 235px;
        padding-left: 10px;
        font-size: 0.9rem;

        .item-info {
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    &.interactive-item {
        cursor: pointer;

        &:hover {
            background-color: $base-color;
            color: white;

            .item-id {
                color: white;
            }
        }
    }


    &.asset-item, &.asset-item.interactive-item {
        .entry-image {
            height: 243px;
            max-height: 100%;
            width: 150px;
            min-width: 150px;

            &.new-planning-image {
                height: 100%;
            }
        }

        .item-body {
            height: 85px;
        }
    }

    &.selected, &.markedForRemove.selected {
        background-color: $entry-item-background-hover;
    }

    &.markedForRemove {
        background-color: $marked-for-remove-color;
    }

    &.document-history-item {
        height: 102px;
    }
}

.img-full {
    max-width: 75vw;
    max-height: 75vh;
    height: auto;
}
.modal-preview {
    max-height: fit-content;
    max-width: fit-content;
    margin: auto;
    top: 50%;
    transform: translateY(-50%)!important;
}


.asset-not-found-item {
    border: solid $default-border-color 1px;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 5px;
    background-color: $base-color;
    overflow: hidden;
    color: white;

    .item-header {
        border-bottom: solid white 1px;
        font-weight: bold;
        width: 100%;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .item-id {
        font-weight: bold;
        width: 100%;
        overflow: hidden !important;
        text-overflow: ellipsis;
        color: #2C7DC7;
    }

    .item-name {
        /*border-bottom: solid white 1px;*/
        font-weight: bold;
        width: 100%;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .item-body {
        padding-left: 10px;
        font-size: 0.9rem;
        height: 40px;
    }

    .item-buttons {
        display: flex;
        justify-content: right;
        padding-bottom: 5px;

        .item-button {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }

            .btn {
                padding: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .entry-image {
        height: 90px;
        max-height: 90px;
        width: 150px;
        min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .entry-details {
        width: 100%;
    }

    .entry-image + .entry-details {
        width: calc(100% - 150px);
    }

    .item-body {
        height: 65px;

        .item-info {
            width: 100%;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .label {
            padding-right: 4px;
        }

        &.asset-height {
            height: 85px;
        }
    }

    &.interactive-item {
        cursor: pointer;

        &:hover {
            background-color: $base-color;
            color: white;
        }
    }


    &.asset-item, &.asset-item.interactive-item {
        .entry-image {
            height: 243px;
            max-height: 100%;
            width: 150px;
            min-width: 150px;
        }

        .item-body {
            height: 85px;
        }
    }
}

.maintenance-operation-item {
    border: solid #e8e8e8 1px;
    border-radius: 15px;
    margin-bottom: 20px;
    background-color: $entry-item-background;
    overflow: hidden;

    .item-header {
        border-bottom: solid $default-border-color 1px;
        font-weight: bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-left: 10px;
    }

    .item-name {
        font-weight: bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-left: 10px;
    }


    .item-body {
        padding-left: 10px;
        font-size: 0.9rem;
        height: 120px;

        .maintenace-operation-task {
            min-height: 10px;
            width: calc(100% - 80px);
        }
    }

    .item-buttons {
        display: flex;
        justify-content: right;
        position: absolute;
        bottom: 30px;
        right: 18px;

        .item-button {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }

            .btn {
                padding: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

    .btn-round {
        border-radius: 50%;
    }

    .card-header {
        /*display: flex;*/
    }


    .modal-content {
        padding: 15px;

        .modal-header {
            padding: 0;
            border-bottom: solid $default-border-color 1px;

            .modal-title {
                font-size: 1.5rem;
            }

            .cancel-button {
                margin-bottom: 5px;
            }

            .save-button {
                margin-bottom: 5px;
            }
        }

        .modal-body {
            padding: 0;
            margin-top: 15px;
        }

        .modal-footer {
            padding: 0;
            padding-top: 15px;
            border-top: solid $default-border-color 1px;
        }
    }

    .no-margin {
        margin: 0 !important;
    }


    footer {
        &.main-footer {
            margin-left: 0 !important;
            padding: 0.5rem;
        }
    }

    div.sidebar {
        height: calc(100vh - 99px);
        min-height: calc(100vh - 99px);
    }

    @media (min-width: 992px) {
        .sidebar-mini.sidebar-collapse .main-sidebar:hover footer.main-footer {
            margin-left: 0 !important;
            display: block;
        }

        .sidebar-mini.sidebar-collapse .main-sidebar footer.main-footer {
            display: none;
        }
    }

    @media (max-width: 992px) {
        .sidebar-mini.sidebar-collapse .main-sidebar:hover footer.main-footer {
            margin-left: 0 !important;
            display: block;
        }

        .sidebar-mini.sidebar-collapse .main-sidebar footer.main-footer {
            display: none;
        }
    }

    .swal2-popup {
        &.swal2-toast {
            .swal2-title {
                margin-bottom: 0 !important;
            }

            .swal2-html-container {
                padding: 0;
            }
        }
    }

    .drag-drop-area {
        min-width: 200px;

        .ngx-file-drop__drop-zone {
            height: 50px !important;
            border: none !important;
            border-radius: 10px !important;

            .ngx-file-drop__content {
                height: 50px;
            }
        }

        .drag-drop-content {
            color: rgba(106,131,172,1);
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 10px;
            border: 2px dotted rgba(106,131,172,1);
            background-color: rgba(106,131,172,0.05);
        }
    }

    .tenant-selection {
        .form-group:not(:last-child) {
            margin-bottom: 0;
        }
    }

    .asset-selected {
        background-color: #67daff;

        &:hover {
            background-color: #67daff !important;
        }
    }

    .all-entries-displayed-text {
        font-size: 13px;
        font-style: italic;
        text-align: center;
    }

    .scan-section-title {
        font-weight: normal !important;
        font-size: x-large;

        a {
            color: black;
        }
    }

    .navbar-filters {
        &.navbar {
            padding: 0px;

            .nav-item {
                padding-right: 10px;

                &:last-child {
                    padding-right: 0px;
                }

                .header-item {

                    &.refresh-button {
                        float: right;
                    }

                    &.filter-date, &.filter-input {
                        width: 150px;
                    }

                    &.filter-custom-input {
                        width: 250px;
                    }

                    &.filter-dialog-input {
                        width: 200px;
                    }

                    &.filter-label {
                        vertical-align: middle;
                        line-height: 38px;
                        font-size: 20px;
                    }

                    &.filter-checkbox-wrapper {
                        height: 100%;
                        padding-bottom: 10px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        &.navbar-light {
            background-color: unset;
        }
    }

    .filter-creation {
        padding: 10px 0px;
    }

    .clear-date {
        position: absolute;
        right: 20px;
        top: 9px;
        background: none;
        border: none;
    }

    @media (max-width: 768px) {
        .navbar-filters {
            .nav-item {
                padding-bottom: 10px;

                /*
            &:last-child {
                padding-bottom: 0px;
            }*/
            }
        }

        .filter-creation {
            padding-bottom: 0px;
        }
    }

    .asset-details-image-wrapper {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border: 1px dashed $default-border-color;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }


    .group-subtitle {
    &.form-group{
            margin-bottom: 0px;
        }

        label {
            font-size: 24px;
            font-weight: normal !important;
            margin-bottom: 0px;
        }

        & + hr {
            margin-top: 0.2rem;
        }
    }

    .ng-select.ng-select-disabled > div.ng-select-container {
        background-color: $input-disabled-background-color;
    }

    .overflow-ellipsis {
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }


    .row-danger {
        background-color: #a94442 !important;
        color: #FFFFFF !important;

        .invalid-row-value {
            color: white !important;
            font-weight: bold;
        }
    }


    .import-table {
        .import-table-header {
            padding-left: 0px;
            padding-right: 0px;
            border-top: 1px solid #dee2e6;
            border-bottom: 2px solid #dee2e6;
            padding-top: 8px;
            padding-bottom: 8px;

            & > div {
                display: inline-block;
                padding-left: 8px;
                padding-right: 8px;
                font-weight: 400;
                font-size: 14px;
                color: #8898AA;
                word-break: break-word;
            }
        }

        .import-table-row {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 0px;
            padding-right: 0px;

            & > div {
                display: inline-block;
                padding-left: 8px;
                padding-right: 8px;
                word-break: break-word;
            }

            &:nth-of-type(odd) {
                background-color: rgba(0,0,0,.05);
            }

            &:hover {
                color: $default-black;
                background-color: rgba(0,0,0,.075);
            }
        }
    }

    @media (min-width: 540px) {
        .loadingModal.modal-dialog {
            width: 478px;
            top: 50%;
        }
    }

    @media (max-width: 540px) {
        .loadingModal.modal-dialog {
            width: auto;
            top: 50%;
        }
        .notify-content-class {
            width: 100%;
        }
    }


    .bg-im {
        background-color: $base-color !important;
        color: white !important;
    }

    .info-box.info-box-small {
        height: calc(50% - 0.5rem - 10px);
        min-height: unset;

        .info-box-content {
            line-height: 1.2;
        }
    }

    @media (max-width: 767.98px) {
        .info-box.info-box-small {
            text-align: center;
        }
    }

    ng-select {
        .ng-dropdown-panel {
            width: auto !important;
            min-width: 250px;
        }
    }


    .input-icons {
        width: 100%;
        margin-bottom: 10px;

        .icon {
            padding: 10px 10px 10px 0px;
            color: $base-color;
            min-width: 45px;
            text-align: center;
        }

        .input-field {
            width: 100%;
            padding: 10px 10px 10px 30px;
        }

        i {
            position: absolute;
        }
    }

    .list-note {
        display: block;
        font-style: italic;
        font-size: 0.8rem;
        margin-bottom: 10px;
    }

    .card-header {
        .list-note {
            display: inline-block;
        }

        .navbar-nav {
            flex-flow: wrap;
        }
    }

    .home-pie-chart {
        justify-content: center;

        &.info-box {
            padding-bottom: 0;
        }

        .chart-legend {
            margin: 0;

            .legend-wrap {
                width: 100%;

                .legend-labels {
                    text-align: center;

                    .legend-label {
                        display: inline-block;

                        .active {
                            .legend-label-text {
                                font-weight: bold;
                            }

                        }

                        .legend-label-text {
                            color: $default-black;
                        }
                    }
                }
            }
        }
    }

.td-users-buttons{
        width: 330px;
    }

    @media (max-width: 576px) {
        .login-box, .register-box {
            width: 100%;
        }
    }

    body.swal2-height-auto {
        &.login-page, &.register-page {
            height: 100vh !important;
        }
    }

    .btn-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;
    }


    .setting {

        margin-bottom: 20px;

        &:not(:last-child) {
            padding-bottom: 20px;
            border-bottom: 1px solid $default-border-color;
        }

        .setting-title {
            margin-bottom: 0.5rem;
            font-family: inherit;
            font-weight: 400;
            line-height: 1.2;
            font-size: 1.0rem;

            .custom-control-label {
                margin-bottom: 0.5rem;
                font-family: inherit;
                font-weight: 400;
                line-height: 1.2;
                font-size: 1.0rem;
            }
        }

        .setting-content {
            padding-left: 20px;

            .form-group {
                .form-control {
                    width: 100px;
                    display: inline-block;
                    height: unset;
                    padding: 0 0.55rem;
                }

                .label {
                    margin-left: 10px;
                    font-size: 0.9rem;
                    font-weight: normal;
                }
            }

            .setting-note {
                font-style: italic;
                font-size: 0.8rem;
            }
        }
    }

    .pac-container {
        z-index: 1051;
    }

    .check-alligned {
        height: 100%;
    }

    .note-label {
        font-style: italic;
        font-size: 0.9rem;
    }

    .ng-dropdown-panel.ng-select-bottom {
        width: 100% !important;
    }

    .outdated-item {
        background-color: #ffffcc;
    }



    @media (min-width: 992px) {
        .inner-scroll {
            height: calc(100vh - 218px);
        }
    }


    .break-page {
        &:not(:last-child) {
            border-bottom: 1px solid black;
            margin-bottom: 30px;
            padding-bottom: 30px;
        }
    }


    .document-radio-choice {
        display: inline-block;

        &:not(:last-child) {
            padding-right: 10px;
        }
    }

    .not-identified-binding-row {
        cursor: pointer;

        &:hover {
            background-color: rgba(0,0,0,.075);
        }

        &:not(.disabled) {
            &.selected {
                background-color: $entry-item-background-hover;
            }
        }

        &.selected {
            background-color: $entry-item-background-hover;
        }



        &:not(:last-child) {
            border-bottom: 1px solid black;
            padding-bottom: 10px;
            padding-top: 10px;
        }

        .not-identified-binding-column {
            display: inline-flex;
            justify-items: center;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        @media (min-width: 576px) {
            .delimiter-item {
                border-right: 1px solid black;
            }
        }

        @media (max-width: 576px) {
            .delimiter-item {
                margin-bottom: 10px;
            }
        }

        .entry-item {
            margin-bottom: 0;
        }
    }


table.table-fixed-header {
    thead {
        z-index: 2;
        position: relative;

        tr {
            th {
                background-color: white;
                border-top: 0;
            }
        }
    }

    tbody {
        tr {
            td {
                .custom-checkbox {
                    z-index: -1;
                }
            }

            &:hover {
                td {
                    .custom-checkbox {
                        z-index: 0;
                    }
                }
            }
        }
    }
}


    .add-select-input-group {
        display: flex;

    .add-button-item  {

            padding-right: 0.5rem;

            & > button {
                padding: 0;
                height: 36px;
                width: 36px;
            }
        }

        .select-item {
            flex-grow: 1;
        }
    }

    .dashboardDetailsRow {
        font-weight: 700;
        color: white;
        align-items: center;

        &.inventory {
            .number-card-wrapper {
                background-color: $base-color;
            }
        }

        &.maintenance {
            .number-card-wrapper {
                background-color: #28a745;
            }
        }

        & > div {
            height: 130px;
            padding-bottom: 15px;
        }

        .number-card-wrapper {
            width: 100%;
            height: 100%;
            padding-right: 7.5px;
            padding-left: 7.5px;
            border-radius: 0.25rem;
        }

        span {
            font-size: 2rem;
        }

        p {
            font-size: 1.35rem;
            font-size: 3.9vw;
        }

        @media (min-width: 576px) {
            p {
                font-size: 1.1rem;
                font-size: 2.35vw;
            }
        }

        @media (min-width: 768px) {
            p {
                font-size: 0.75rem;
                font-size: 1.55vw;
            }
        }

        @media (min-width: 992px) {
            p {
                font-size: 0.75rem;
                font-size: 1.20vw;
            }
        }

        @media (min-width: 1200px) {
            p {
                font-size: 0.9rem;
            }
        }
    }

    .warning-box {
    color:red;
        margin: 10px;
    }

    .red-style {
        color: red !important;
    }


    .action-row {
        cursor: pointer;
    }

    .legend-label {
    float:left;
    }

    .toggler-button {
        width: 1em;
        height: 1em;
    }

    .td-paragraph {
        padding-left: 20px !important;
    }

    .pink-row {
        background: $controls-pink;

        &:hover {
            background: $controls-pink-hover !important;
            color: white;
        }
    }

.check-index{
        z-index: auto !important;
    }

    .legend-square {
        height: 18px;
        width: 18px;

        &.pink {
            background: $controls-pink;
        }

        &.white {
            background: white;
            border: 1px solid gray;
        }
    }

    .legend-table {
        tbody {
            tr {
                td {
                    padding: 3px;
                    font-size: 1rem;
                }
            }
        }
    }

    .settings-label {
        font-size: 0.9rem;
    }

    .navbar-top-allign {
        align-items: flex-start;
    }

    .nav-item-new-line {
        width: 100%;
        margin-bottom: 5px;
    }

    perfect-scrollbar {
        padding-right: 5px;
    }

    ng-select .ng-dropdown-panel {
        min-width: 150px;
    }

    .filter-button {
        font-size: medium;
    }

@media (min-width: 768px){
        .navbar-custom {
            float: right;
        }
    .navbar-custom-title{
            display: none;
        }
    }

    .custom-page-checkbox {
        .custom-control-label {
            cursor: pointer;
            font-size: 0.9rem;
            font-weight: bold !important;
            color: $base-color;

            &::before {
                border: 1px solid $base-color;
                border-radius: 3px;
                background-color: #fff;
                font-weight: bold !important;
            }
        }

        .custom-control-input {
            &:checked ~ .custom-control-label {
                &::before {
                    border: 1px solid $base-color;
                    border-radius: 3px;
                    background-color: #fff;
                }

                &::after {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='rgb(0, 153, 255)' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
                    color: $base-color;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .modal-height-planning {
            height: calc(100vh - 202px) !important;
        }
    }

    .ngx-charts-outer {
        height: unset !important;
    }

    .equal-stock {
        color: #19b100;
    }

    .not-equal-stock {
        color: #ff2222;
    }


.onPremiseNotify {
    a {
        color: white;
    }
}

.invalid-outline {
    border-color: #03a9f4 !important;
}

perfect-scrollbar.custom-height {

    & > .ps {
        max-height: inherit;
    }
}

.ng-select.wide-dropdown {

    ng-dropdown-panel {
        min-width: 100%;
    }
}

.beneficiary-card-count {
    font-weight: bold;
}

.beneficiary-assets-table {
    tr {
        &:first-child {
            & > td {
                border-top: 0;
            }
        }
    }
}

.maintenance-task-preview {
    &:last-child {
        padding-bottom: 15px;
    }
}

.form-item-vertical-center {
    display: flex;
    align-items: center;
}

@media (min-width: 769px) {
    .btn-operation-preview-asset {
        display: inline;
        float: right;
        padding-bottom: 0px;
        margin-right: -20px;
    }
}

@media (max-width: 769px) {
    .btn-operation-preview-asset {
        display: inline;
        float: right;
        padding-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .btn-operation-preview-maintenance {
        display: inline;
        float: right;
        padding-bottom: 0px;
        margin-right: -20px;
    }
}

@media (max-width: 992px) {
    .btn-operation-preview-maintenance {
        display: inline;
        float: right;
        padding-bottom: 0px;
    }
}

.scan-webcam > div > video {
    width: 100%;
}

$technician-done-color: #01b601;

$technician-pending-color: orange;

.technician-team-info {
    color: white;
    border-radius: 50%;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    cursor: pointer;
    position: relative;


    .background {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: inherit;
    }

    .text-counts {
        font-size: 0px;
        z-index: 1;

        span {
            font-size: 14px;
            line-height: 14px;
            z-index: 1;
        }
    }

    &.technician-team-merged-info {

        .background {
            background: linear-gradient(to right, $technician-done-color 0%,$technician-done-color 50%,$technician-pending-color 50%,$technician-pending-color 100%);
            transform: rotate(20deg);
        }
    }

    &.technician-team-background-done-info {

        .background {
            background-color: $technician-done-color;
        }
    }
}


table.table-fixed-header {
    & > thead {
        z-index: 2;
        position:relative;
    }
}

.import-table-checkbox-column {
    width: 2%;
}
